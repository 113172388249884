import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import InactiveInventoryBarGraph from "../../../components/inventory/inactiveInventoryBarGraph";
import InactiveInventoryTable from "../../../components/inventory/inactiveInventoryTable";
import InactiveInventoryWaterfall from "../../../components/inventory/inactiveInventoryWaterFallGraph";
import InactiveInventoryWaterfall1 from "../../../components/inventory/inactiveInventoryWaterFallGraph1";
import { useLocation } from "react-router-dom";
import {
  getInactiveInventoryChartData,
  getInactiveInventoryData,
  getInactiveInventoryPlantChartData,
  getInactiveInventoryWaterfallData,
} from "../../../queries/apiQueries";
import InactiveInventoryZDBarGraph from "../../../components/inventory/inactiveInventoryZDBarGraph";
import InactiveInventoryTimeframeBarGraph from "../../../components/inventory/inactiveInventoryTimeframeBarGraph";
import { useSelector } from "react-redux";

const InactiveInventoryPage = (props: any) => {
  const location = useLocation();
  const type = new URLSearchParams(location.search).get("type");
  const [loading, setLoading] = useState(false);

  const [barGraphData, setBarGraphData] = useState<any>(null);
  const [barGraphData1, setBarGraphData1] = useState<any>(null);
  const [barGraphDataZD, setBarGraphDataZD] = useState<any>(null);
  const [barGraphDataZDSKUl, setBarGraphDataZDSKUl] = useState(null);
  const [barGraphDataAge, setBarGraphDataAge] = useState(null);
  const [barGraphDataAge1, setBarGraphDataAge1] = useState(null);
  const [inactiveTaggedData, setInactiveTaggedData] = useState(null);
  const [inactiveTimeframeData, setInactiveTimeframeData] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState("");
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );

  const getBarGraphDataHandler = () => {
    let payload = {
      ...selectedGBPFilter,
      alertType: Number(type),
    };
    if(selectedCluster!==''){
      payload={...payload,clustername:[selectedCluster]}
    }
    getInactiveInventoryChartData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          prepareBarGraphData(response?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getInactiveInventoryWaterfallDataHandler = () => {
    let payload = {
      ...selectedGBPFilter,
      alertType: Number(type),
    };
    if(selectedCluster!==''){
      payload={...payload,clustername:[selectedCluster]}
    }
    getInactiveInventoryWaterfallData(payload)
      .then((response: any) => {
        if (response?.status === 200) {
          prepareWaterfallData(response?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getInactiveInventoryPlantChartDataHandler = (data: any) => {
    setSelectedCluster(data?.plant);
    getInactiveInventoryPlantChartData({
      plant: data?.plant,
      alertType: Number(type),
    })
      .then((response: any) => {
        if (response?.status === 200) {
          let finalData
          if(type==='2'){
            let d1=response?.data?.data?.filter(
              (value: any) => value?.plant !== data?.plant
            );
            let d2=response?.data?.vendor?.filter(
              (value: any) => value?.plant !== data?.plant
            );
            finalData={data:d1,vendor:d2}
          }else{
            finalData = response?.data?.data?.filter(
              (value: any) => value?.plant !== data?.plant
            );
          }
          prepareBarGraphData(finalData, Number(type) + 1);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(selectedGBPFilter).length !== 0) {
      // setLoading(true)
      if (type === "0") {
        getBarGraphDataHandler();
      } else if (type === "1" || type === "2") {
        getInactiveInventoryWaterfallDataHandler();
      }
    }
  }, [type, selectedGBPFilter,selectedCluster]);

  const prepareWaterfallData = (data: any) => {
    const { taggedData, timeframeData, taggedData1, timeframeData1 } = data;

    let obj1: any = new Object();
    timeframeData1?.forEach((value: any) => {
      if (obj1.hasOwnProperty(value?.plant_code)) {
        console.log(value?.InventoryAgeBuckets, 2);
        if (value?.InventoryAgeBuckets === ">12Months") {
          obj1[value?.plant_code].M8 += value?.onhand;
        } else {
          obj1[value?.plant_code].M812 += value?.onhand;
        }
        obj1[value?.plant_code].sum += value?.onhand;
      } else {
        console.log(value?.InventoryAgeBuckets, 1);
        obj1[value?.plant_code] = {
          cluster: value?.plant_code,
          M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
          M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
          sum: value?.onhand,
        };
      }
    });

    let finalData3: any = [];
    for (let key in obj1) {
      finalData3.push(obj1[key]);
    }
    finalData3.sort((a: any, b: any) => b?.sum - a?.sum);

    setBarGraphDataAge(finalData3);

    let obj: any = new Object();
    taggedData1?.forEach((value: any) => {
      if (obj.hasOwnProperty(value?.plant_code)) {
        if (value?.Retagging_to_ZD_Alert) {
          obj[value?.plant_code].ZD += value?.onhand;
        } else {
          obj[value?.plant_code].notZD += value?.onhand;
        }
        obj[value?.plant_code].sum += value?.onhand;
      } else {
        obj[value?.plant_code] = {
          cluster: value?.plant_code,
          ZD: value?.Retagging_to_ZD_Alert ? value?.onhand : 0,
          notZD: !value?.Retagging_to_ZD_Alert ? value?.onhand : 0,
          sum: value?.onhand,
        };
      }
    });

    let finalData: any = [];
    for (let key in obj) {
      finalData.push(obj[key]);
    }
    finalData.sort((a: any, b: any) => b?.sum - a?.sum);
    // setBarGraphDataZD(finalData);

    let obj2: any = new Object();
    taggedData1?.forEach((value: any) => {
      if (obj2.hasOwnProperty(value?.plant_code)) {
        if (value?.Retagging_to_ZD_Alert) {
          obj2[value?.plant_code].ZD += value?.SKUl;
        } else {
          obj2[value?.plant_code].notZD += value?.SKUl;
        }
        obj2[value?.plant_code].sum += value?.SKUl;
      } else {
        obj2[value?.plant_code] = {
          cluster: value?.plant_code,
          ZD: value?.Retagging_to_ZD_Alert ? value?.SKUl : 0,
          notZD: !value?.Retagging_to_ZD_Alert ? value?.SKUl : 0,
          sum: value?.SKUl,
        };
      }
    });

    let finalData4: any = [];
    for (let key in obj2) {
      finalData4.push(obj2[key]);
    }
    finalData4.sort((a: any, b: any) => b?.sum - a?.sum);
    // setBarGraphDataZDSKUl(finalData4);
    setBarGraphDataZD({cluster:finalData,skul:finalData4});

    // let finalData1: any = [
    //   {
    //     name: "Total Inactive Inv",
    //     uv: taggedData[0]?.onhand,
    //     pv: 0,
    //   },
    //   {
    //     name: "Unaddressable Inactive",
    //     uv: taggedData[0]?.unaddressable,
    //     pv: taggedData[0]?.onhand,
    //   },
    //   {
    //     name: "Addressable Inactive",
    //     uv: taggedData[0]?.addressable,
    //     pv: 0,
    //   },
    // ];
    // setInactiveTaggedData(finalData1);

    let obj4: any = new Object();
    taggedData?.forEach((value: any) => {
      if (obj4.hasOwnProperty(value?.Vendor_Code)) {
        console.log(value?.InventoryAgeBuckets, 2);
        if (value?.InventoryAgeBuckets === ">12Months") {
          obj4[value?.Vendor_Code].M8 += value?.onhand;
        } else {
          obj4[value?.Vendor_Code].M812 += value?.onhand;
        }
        obj4[value?.Vendor_Code].sum += value?.onhand;
      } else {
        console.log(value?.InventoryAgeBuckets, 1);
        obj4[value?.Vendor_Code] = {
          cluster: value?.Vendor_Code,
          M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
          M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
          sum: value?.onhand,
        };
      }
    });

    let finalData5: any = [];
    for (let key in obj4) {
      finalData5.push(obj4[key]);
    }
    finalData5.sort((a: any, b: any) => b?.sum - a?.sum);

    setBarGraphDataAge1(finalData5);

    let finalData2: any = [
      {
        name: "Inactive Inv",
        uv: timeframeData[0]?.onhand,
        pv: 0,
      },
      ...(type === "1"
        ? [
            {
              name: "<8 Months",
              uv: timeframeData[0]?.month8,
              pv: timeframeData[0]?.onhand,
            },
          ]
        : []),

      {
        name: "8-12 Months",
        uv: timeframeData[0]?.month812,
        pv: timeframeData[0]?.onhand + timeframeData[0]?.month8,
      },
      {
        name: ">12 Months",
        uv: timeframeData[0]?.month12,
        pv: 0,
      },
    ];
    console.log(finalData2, "final");
    setInactiveTimeframeData(finalData2);
  };

  const prepareBarGraphData = (data: any, key: number = 0) => {
    let obj: any = new Object();
    if (key === 3) {
      console.log(data,key,11)
      data?.data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant)) {
          console.log(value?.InventoryAgeBuckets, 2);
          if (value?.InventoryAgeBuckets === ">12Months") {
            obj[value?.plant].M8 += value?.onhand;
          } else {
            obj[value?.plant].M812 += value?.onhand;
          }
          obj[value?.plant].sum += value?.onhand;
        } else {
          console.log(value?.InventoryAgeBuckets, 1);
          obj[value?.plant] = {
            cluster: value?.plant,
            M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
            M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
      let obj2:any=new Object()
      data?.vendor?.forEach((value: any) => {
        if (obj2.hasOwnProperty(value?.plant)) {
          console.log(value?.InventoryAgeBuckets, 2);
          if (value?.InventoryAgeBuckets === ">12Months") {
            obj2[value?.plant].M8 += value?.onhand;
          } else {
            obj2[value?.plant].M812 += value?.onhand;
          }
          obj2[value?.plant].sum += value?.onhand;
        } else {
          console.log(value?.InventoryAgeBuckets, 1);
          obj2[value?.plant] = {
            cluster: value?.plant,
            M8: value?.InventoryAgeBuckets === ">12Months" ? value?.onhand : 0,
            M812: value?.InventoryAgeBuckets === "8-12Months" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
  

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      let finalData2: any = [];

      for (let key in obj2) {
        finalData2.push(obj2[key]);
      }
      finalData2.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData1({cluster:finalData,vendor:finalData2});
    }else if (key === 2) {
      data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant)) {
          if (value?.Retagging_to_ZD_Alert) {
            obj[value?.plant].ZD += value?.onhand;
          } else {
            obj[value?.plant].notZD += value?.onhand;
          }
          obj[value?.plant].sum += value?.onhand;
        } else {
          obj[value?.plant] = {
            cluster: value?.plant,
            ZD: value?.Retagging_to_ZD_Alert ? value?.onhand : 0,
            notZD: !value?.Retagging_to_ZD_Alert ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });
      let obj2:any=new Object()
      data?.forEach((value: any) => {
        if (obj2.hasOwnProperty(value?.plant)) {
          if (value?.Retagging_to_ZD_Alert) {
            obj2[value?.plant].ZD += value?.SKUl;
          } else {
            obj2[value?.plant].notZD += value?.SKUl;
          }
          obj2[value?.plant].sum += value?.SKUl;
        } else {
          obj2[value?.plant] = {
            cluster: value?.plant,
            ZD: value?.Retagging_to_ZD_Alert ? value?.SKUl : 0,
            notZD: !value?.Retagging_to_ZD_Alert ? value?.SKUl : 0,
            sum: value?.SKUl,
          };
        }
      });

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      let finalData2: any = [];

      for (let key in obj2) {
        finalData2.push(obj2[key]);
      }
      finalData2.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData1({cluster:finalData,skul:finalData2});
    } else if (key === 1) {
      data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.plant)) {
          if (value?.segment === "X1. No Demand In Last 6M") {
            obj[value?.plant].x1 = value?.onhand;
          } else if (value?.segment === "X2. No Demand In Last 7M") {
            obj[value?.plant].x2 = value?.onhand;
          }
          obj[value?.plant].sum += value?.onhand;
        } else {
          obj[value?.plant] = {
            cluster: value?.plant,
            x1:
              value?.segment === "X1. No Demand In Last 6M" ? value?.onhand : 0,
            x2:
              value?.segment === "X2. No Demand In Last 7M" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData1(finalData);
    } else {
      data?.data?.forEach((value: any) => {
        if (obj.hasOwnProperty(value?.cluster)) {
          if (value?.segment === "X1. No Demand In Last 6M") {
            obj[value?.cluster].x1 = value?.onhand;
          } else if (value?.segment === "X2. No Demand In Last 7M") {
            obj[value?.cluster].x2 = value?.onhand;
          }
          obj[value?.cluster].sum += value?.onhand;
        } else {
          obj[value?.cluster] = {
            cluster: value?.cluster,
            x1:
              value?.segment === "X1. No Demand In Last 6M" ? value?.onhand : 0,
            x2:
              value?.segment === "X2. No Demand In Last 7M" ? value?.onhand : 0,
            sum: value?.onhand,
          };
        }
      });

      let obj1 = [
        {
          label: "X1toX3",
          value: data?.movement[0]?.X1toX3,
        },
        {
          label: "X1toX2",
          value: data?.movement[0]?.X1toX2,
        },
        {
          label: "X2toX3",
          value: data?.movement[0]?.X2toX3,
        },
      ];

      let finalData: any = [];

      for (let key in obj) {
        finalData.push(obj[key]);
      }
      finalData.sort((a: any, b: any) => b?.sum - a?.sum);
      setBarGraphData({ stacked: finalData, movement: obj1 });
    }
  };

  return (
    <>
      <p className="text-mainGreen text-1.3vw text-center ">
        {type === "0"
          ? `Potential Inactive
(No demand in last 6M)`
          : type === "1"
          ? `Untagged Inactive`
          : `Ageing Inactive
(Age > 8 Months)`}
        ​
      </p>
      <div className=" mt-2">
        {loading && (
          <Loader backdrop size="md" inverse center content="loading..." />
        )}

        <div className="w-full flex justify-center">
          <div className="w-[95%]">
            <div className={`grid  grid-cols-2 gap-6`}>
              {type === "0" && (
                <InactiveInventoryBarGraph
                  barGraphData={barGraphData}
                  barGraphData1={barGraphData1}
                  onbarClick={getInactiveInventoryPlantChartDataHandler}
                  goBack={() => {
                    setBarGraphData1(null);
                    setSelectedCluster("");
                  }}
                  selectedCluster={selectedCluster}
                />
              )}
              {type === "0" && (
                <InactiveInventoryBarGraph
                  barGraphData={barGraphData}
                  graphType={1}
                />
              )}
              {type === "1" && (
                // <InactiveInventoryWaterfall1
                //   inactiveTaggedData={inactiveTaggedData}
                // />
                <InactiveInventoryZDBarGraph
                  onbarClick={getInactiveInventoryPlantChartDataHandler}
                  goBack={() => {
                    setBarGraphData1(null);
                    setSelectedCluster("");
                  }}
                  barGraphData1={barGraphData1}
                  selectedCluster={selectedCluster}
                  barGraphData={barGraphDataZD}
                  barGraphDataZDSKUl={barGraphDataZDSKUl}
                />
              )}
              {type === "2" && (
                // <InactiveInventoryWaterfall1
                //   inactiveTaggedData={inactiveTaggedData}
                // />
                <InactiveInventoryTimeframeBarGraph
                  barGraphData={{
                    cluster: barGraphDataAge,
                    vendor: barGraphDataAge1,
                  }}
                  onbarClick={getInactiveInventoryPlantChartDataHandler}
                  goBack={() => {
                    setBarGraphData1(null);
                    setSelectedCluster("");
                  }}
                  barGraphData1={barGraphData1}
                  selectedCluster={selectedCluster}
                />
              )}

              {(type === "1" || type === "2") && (
                <InactiveInventoryWaterfall
                  inactiveTimeframeData={inactiveTimeframeData}
                />
              )}
            </div>
            <div className="w-full flex justify-center items-center">
              <div className="w-full mt-5">
                <InactiveInventoryTable selectedCluster={selectedCluster} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InactiveInventoryPage;
