import { FILTERDATA, PLANTCODEFILTER } from "../components/common/filters";
import { TOKENDATA, WINDOWSIZE } from "../components/common/header";
import { TABLEFILTER } from "../components/forecast/forecastDataTable";
import {
  CLUSTERNAME,
  FUTURESEGMENTATION,
  L2,
  L3,
  MATERIAL,
  MATERIALDESCRIPTION,
  MRPTYPE,
  PFXPG,
  PLANT,
  POTYPE,
  VENDORNAME,
} from "../queries/apiQueries";

const initialState = {
  gbpfilter: {
    plant: [],
    l2: [],
    l3: [],
    materialDescription: [],
    material: [],
    mrpType: [],
    futureSegmentation: [],
    vendorName: [],
    clustername: [],
    poType:[]
  },
  selectedGBPFilter: {plant: [],
    l2: [],
    l3: [],
    materialDescription: [],
    material: [],
    mrpType: [],
    futureSegmentation: [],
    vendorName: [],
    clustername: [],},
  selectedPlantCode:{},
  token: null,
  tableSelection:[],
  windowSize:{}
};

const CommonReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PLANT:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          plant: action.data,
        },
      };
    case L2:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          l2: action.data,
        },
      };
    case L3:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          l3: action.data,
        },
      };
    case MATERIALDESCRIPTION:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          materialDescription: action.data,
        },
      };
    case MATERIAL:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          material: action.data,
        },
      };
    case MRPTYPE:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          mrpType: action.data,
        },
      };
    case FUTURESEGMENTATION:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          futureSegmentation: action.data,
        },
      };
    case VENDORNAME:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          vendorName: action.data,
        },
      };
    case CLUSTERNAME:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          clustername: action.data,
        },
      };
    case POTYPE:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          poType: action.data,
        },
      };
      case PFXPG:
      return {
        ...state,
        gbpfilter: {
          ...state.gbpfilter,
          pfxpg: action.data,
        },
      };
    case FILTERDATA:
      return {
        ...state,
        selectedGBPFilter: action.data,
      };
      case PLANTCODEFILTER:
      return {
        ...state,
        selectedPlantCode: action.data,
      };
    case TOKENDATA:
      return {
        ...state,
        token: action.data,
      };
    case TABLEFILTER:
      return {
        ...state,
        tableSelection: action.data,
      };  
    case WINDOWSIZE:
      return {
        ...state,
        windowSize: action.data,
      };  
  }
  return state;
};

export default CommonReducer;
