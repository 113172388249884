import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useSelector } from "react-redux";
import { formatAmount } from "../../utils/commonFunctions";

const InactiveInventoryWaterfall = (props: any) => {

  const {inactiveTimeframeData}=props

  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  return (
    <div className="w-full">
      <span className="text-white font-medium text-0.95vw ">Inventory Age Breakdown  of Untagged Inactive
</span>
      
      <ResponsiveContainer
          width={"100%"}
          height={windowSize?.width / 6.5}
          className={`border-[1px] border-mainGray`}
        >
          <BarChart
            data={inactiveTimeframeData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis fontSize={10} stroke="#B5B5B5" dataKey="name" />
            <YAxis fontSize={10} stroke="#B5B5B5" tickFormatter={(value: any) => value && ` $${formatAmount(value)}`} />
            <Tooltip cursor={{fill: 'transparent'}} content={($) => <CustomTooltip data={$} />} />
            <Bar dataKey="pv" stackId="a" barSize={60} fill="transparent" />
            <Bar
              dataKey="uv"
              name=""
              stackId="a"
              fill="#82ca9d"
              barSize={60}
            >
              {inactiveTimeframeData?.map((item: any, index: any) => {
                if (item.uv < 0) return <Cell key={index} fill="#f7ff05" />;
                if (item.name !== ""&&item.name !== ">12 Months")
                  return <Cell key={index} fill="#57A4AD" />;
                return <Cell key={index} fill="#f78383" />;
              })}
              <LabelList dataKey="uv" content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>

    </div>
  );
};

const renderCustomizedLabel = (props: any) => {
  const { x, y, width, value,name } = props;
  return (
    <>
      {value!==0&&<text x={x + width / 2} y={y- (value>0?10:-10) } fill='#fff' textAnchor="middle" dominantBaseline="middle" >
      ${formatAmount(Number(parseFloat(value<0?value*-1:value).toFixed(2)))}
      </text>}
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;
  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.name}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  {$?.name !== "pv" && (
                    <>
                      {payload[0]?.payload?.name !== "" && (
                        <p className="text-white text-0.7vw">
                          Total :  ${($?.value<0?formatAmount(($?.value*-1)):formatAmount($?.value))}
                        </p>
                      )}
                      {payload[0]?.payload?.name === "" && (
                        <p className="text-white text-0.7vw">
                            ${($?.value<0?formatAmount($?.value*-1):formatAmount($?.value))}
                        </p>
                      )}
                    </>
                  )}
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default InactiveInventoryWaterfall;
