import React, { useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const InactiveInventoryTimeframeBarGraph = (props: any) => {

  const {barGraphData,onbarClick,goBack,selectedCluster,barGraphData1}=props
  const [graphTab, setGraphTab] = useState(1);
    console.log(barGraphData,312)
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );
  console.log(barGraphData1,'skul',graphTab)
  return (
    <div className="w-full ">
      <div className="flex justify-between">
        <div>
      {!barGraphData1&&<span className="text-white font-medium text-0.95vw ">{graphTab===1?'Clusterwise':'Vendor'} Inactive Age Breakdown </span>}
      {barGraphData1&&<span className="text-white font-medium text-0.95vw ">Plantwise Inactive Age Breakdown-{selectedCluster} </span>}
      {barGraphData1&&<Button
                onClick={() => {
                  goBack();
                }}
                size="xs"
                appearance="ghost"
                className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
              >
                Go Back
              </Button>}
        </div>
              <ButtonToolbar className="">
            <ButtonGroup>
              <Button
                onClick={() => {
                  setGraphTab(1);
                }}
                size="xs"
                appearance="ghost"
                className={`${
                  graphTab === 1
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                Cluster
              </Button>
                <Button
                  onClick={() => setGraphTab(2)}
                  appearance="ghost"
                  size="xs"
                  className={`${
                    graphTab === 2
                      ? "bg-green-500 text-white"
                      : "bg-white text-black"
                  } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
                >
                  Vendor
                </Button>

            </ButtonGroup>
          </ButtonToolbar>
      </div>
    <ResponsiveContainer
      width={"100%"}
      height={windowSize?.width / 6.5}
      className={`border-[1px]  border-mainGray`}
    >
      <BarChart
        width={500}
        height={300}
        data={barGraphData1?(graphTab===1?barGraphData1?.cluster:barGraphData1?.vendor):(graphTab===1?barGraphData?.cluster:barGraphData?.vendor)}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <XAxis fontSize={10} stroke="#B5B5B5" dataKey="cluster" angle={-45}
        dx={0}
        dy={15}
        minTickGap={-200}
        axisLine={false}/>
        <YAxis stroke="#B5B5B5" fontSize={10}  tickFormatter={(value: any) => value && `$${formatAmount(value)}`}/>
        <Tooltip cursor={{fill: 'transparent'}} content={($) => <CustomTooltip data={$} />} />
        <Legend verticalAlign="top" />
        <Bar dataKey="M8" onClick={($) => onbarClick({ plant: $?.cluster })} name={'>12 Months'}  barSize={60} stackId="a" fill="#f78383" >
        </Bar>
          <Bar dataKey="M812" onClick={($) => onbarClick({ plant: $?.cluster })}  name={'8-12 Months'}   barSize={60}  stackId="a" fill="#f7ff05" >
          </Bar>
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};

const renderCustomizedLabel = (props: any,key:number,type:number=0) => {
  const { x, y, width, value } = props;
  return (
    <>
      <text x={x + width / 2} y={y+10 } fill='#fff' fontSize={10} textAnchor="middle" dominantBaseline="middle" >
        {value>500000&&type===0&&<>
        {key===1&&value>100000?'>12':'8-12'}
        </>}
        {value>10000&&type===1&&<>
        {key===1&&value>10000?'>12':'8-12'}
        </>}
      </text>
    </>
  );
};

const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;

  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.cluster}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p className="text-white text-0.7vw">
                    {$?.name?.toUpperCase()} : ${formatNumber(parseFloat($?.value))}
                  </p>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default InactiveInventoryTimeframeBarGraph;
