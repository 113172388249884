import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Link } from "react-router-dom";
import { getGBPAlerts } from "../../queries/apiQueries";
import { AlertData, GBPFilters } from "../../model/forecastModel";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import { appInsights } from "../router";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
const Alerts = () => {
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );
  const token = useSelector((state: any) => state.CommonReducer.token);

  const [alertData, setAlertData] = useState<AlertData>();
  const [loading, setLoading] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState<GBPFilters>({
    plant: [],
    l2: [],
    l3: [],
    materialDescription: [],
    material: [],
    mrpType: [],
    futureSegmentation: [],
    vendorName: [],
    clustername: [],
    alerts: [],
    reviewedFlag: [],
    poAlert:[],
    adjustedLogic:[],
    poType:[],
    pfxpg:[]
  });

  const getGBPAlertData = async () => {
    getGBPAlerts(selectedGBPFilter)
      .then((response) => {
        if (response?.status === 200) {
          setAlertData(response?.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: `Navigate to Alerts by ${localStorage.getItem("name")}`,
    });
    if (selectedGBPFilter) {
      setSelectedFilterData(selectedGBPFilter);
    }
  }, [selectedGBPFilter]);

  useEffect(() => {
    setLoading(true);
    if (token&&Object.keys(selectedGBPFilter).length !== 0) {
      getGBPAlertData();
    }
  }, [selectedGBPFilter, token]);

  return (
    <>
      <p className="text-mainGreen text-1.5vw text-center my-2">Alerts</p>
      <div className="p-2 w-full flex justify-center ">
        {loading && <Loader backdrop size="md" inverse center content="loading..." />}

        {!loading && (
          <div className="w-[95%] ">
            <div className="w-full border-b-2 border-teal-700"></div>

            <div className="w-full grid grid-cols-3">
              <div className="col-span-2 ">
                <div className="w-full flex justify-center">
                  <span className="text-1.3vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                    Short Term - {alertData?.shortTermCount}
                  </span>
                </div>
                {/* <div className="w-full flex justify-center">
                <div className=" w-9/12 grid grid-cols-2 ">
                  {alertData?.shortTerm?.map((element, index: number) => {
                    return (
                      <>
                        {index < 2 && (
                          <div key={index} className="flex justify-center">
                            <Link
                                      to={{
                                        pathname: "/forecast/forecast-overview",
                                        search: `?alert=${element?.alerts}`,
                                      }} style={{ textDecoration: 'none' }}
                                    >
                            <Card
                              style={{ backgroundColor: "#000000" }}
                              className="h-[12vw] w-[12.5vw]"
                            >
                              <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full ">
                                <div className="w-full h-full grid grid-cols-1">
                                  <p
                                    className={` text-0.95vw text-center text-[#00FFFF] font-medium`}
                                  >
                                    {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )}
                                  </p>
                                  <p className="text-white text-1.8vw text-center  font-semibold">
                                    {formatNumber(element?.count)}
                                  </p>
                                  <p className="text-white text-1.3vw text-center  font-semibold">
                                    ${formatAmount(element?.annualRevenue)}
                                  </p>
                                  <div className="w-full flex justify-end ">
                                    
                                      <KeyboardDoubleArrowRightIcon
                                        className="text-[#00FFFF] "
                                        sx={{
                                          fontSize: {
                                            xs: 10,
                                            sm: 20,
                                            md: 30,
                                            lg: 40,
                                          },
                                        }}
                                      />
                                    
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                            </Link>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
                </div> */}
                
                <div className="w-full grid grid-cols-3 gap-6">
                  {alertData?.shortTerm?.map((element, index: number) => {
                    return (
                      <>
                        { (
                          <div key={index}  className="flex justify-center ">
                            <Link
                                      to={{
                                        pathname: "/forecast/forecast-overview",
                                        search: `?alert=${element?.alerts}`,
                                      }} style={{ textDecoration: 'none' }}
                                    >
                            <Card
                              style={{ backgroundColor: "#000000" }}
                              className="h-[12vw] w-[12.5vw]"
                            >
                              <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full ">
                                <div className="w-full h-full grid grid-cols-1">
                                  <p
                                    className={` text-0.95vw text-center text-[#00FFFF] font-medium`}
                                  >
                                    {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )}
                                  </p>
                                  <p className="text-white text-1.8vw text-center  font-semibold">
                                    {formatNumber(element?.count)}
                                  </p>
                                  <p className="text-white text-1.3vw text-center  font-semibold">
                                    ${formatAmount(element?.annualRevenue)}
                                  </p>
                                  <div className="w-full flex justify-end ">
                                    
                                      <KeyboardDoubleArrowRightIcon
                                        className="text-[#00FFFF] "
                                        sx={{
                                          fontSize: {
                                            xs: 10,
                                            sm: 20,
                                            md: 30,
                                            lg: 40,
                                          },
                                        }}
                                      />
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                            </Link>
                          </div>
                        )}
                      </>
                    );
                  })}
                
                </div>
              </div>

              <div className="border-l-2 border-teal-700">
                <div className="w-full flex justify-center">
                  <span className="text-1.3vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                    Long Term - {alertData?.longTermCount}
                  </span>
                </div>
                <div className="grid grid-cols-1 gap-6 w-full ">
                  {alertData?.longTerm?.map((element,index:number)=>{
                    return(
<div className="flex justify-center" key={index}>
<Link
                              to={{
                                pathname: "/forecast/forecast-overview",
                                search: `?alert=${element?.alerts}${element?.alerts==='HighOutlierCorrection'?'&type=1':''}`,
                              }} style={{ textDecoration: 'none' }}

                            >
                    <Card
                      style={{ backgroundColor: "#000000" }}
                      className="h-[12vw] w-[12.5vw]"
                    >
                      <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full py-5">
                        <div className="w-full h-full grid grid-cols-1">
                          <p
                            className={` text-[0.9vw] text-center text-mainGreen font-medium`}
                          >
                            {element?.alerts.replace(/([a-z])([A-Z])/g, "$1 $2")}
                          </p>
                          <p className="text-white text-1.8vw text-center  font-semibold">
                            {formatNumber(element?.count)}
                          </p>
                          <p className="text-white text-1.3vw text-center  font-semibold">
                            ${formatAmount(element?.annualRevenue)}
                          </p>
                          <div className="w-full flex justify-end ">
                            
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen "
                                sx={{
                                  fontSize: { xs: 10, sm: 20, md: 30, lg: 40 },
                                }}
                              />
                            
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                    </Link>
                  </div>
                    )
                  })}

                </div>
              </div>
            </div>

            <div className="w-full text-white text-0.7vw  mt-6">
              <span>Revenue against each alert represents corresponding last 12 month Sales $ using current board cost</span>
            </div>

            {/* {alertData?.data?.map((data, index) => {
              return (
                <Card
                  key={index}
                  style={{ backgroundColor: "#000000"}}
                  className="h-[14vw]"
                >
                  <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full ">
                    <div className="w-full h-full grid grid-cols-1">
                      <p
                        className={` text-1.3vw text-center ${
                          data?.alerts === "TBD" ? "text-black" : "text-mainGreen"
                        }`}
                      >
                        {data?.alerts.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      </p>
                      <p className="text-white text-[2vw] text-center mt-8 font-semibold">
                        {formatNumber(data?.count)}
                      </p>
                      <div className="w-full flex justify-end ">
                        <Link
                          to={{
                            pathname: "/forecast",
                            search: `?alert=${data?.alerts}`,
                          }}
                        >
                          <FileDownloadIcon
                            className="text-mainGreen "
                            sx={{fontSize: { xs: 20, sm: 30, md: 40, lg: 50 } }}
                          />
                        </Link>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              );
            })} */}
          </div>
        )}
      </div>
    </>
  );
};

export default Alerts;
