import React, { useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { useSelector } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from "rsuite";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";

const InactiveInventoryZDBarGraph = (props: any) => {

  const {barGraphData,onbarClick,goBack,selectedCluster,barGraphData1}=props
  const [graphTab,setGraphTab]=useState(1)
 
  const windowSize = useSelector(
    (state: any) => state.CommonReducer.windowSize
  );

  return (
    <div className="w-full ">
      <div className="flex justify-between ">
        <div>
      <span className="text-white font-medium text-0.95vw ">{barGraphData1?`Plantwise Breakdown of Untagged Inactive - ${selectedCluster}`: `Clusterwise Breakdown of Untagged Inactive`} </span>
      {barGraphData1 && (
          <Button
            onClick={() => {
              goBack();
            }}
            size="xs"
            appearance="ghost"
            className={` bg-green-500 text-white
                 border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white ml-3`}
          >
            Go Back
          </Button>
        )}
        </div>
      <ButtonToolbar className=" ">
            <ButtonGroup>
              <Button
                onClick={() => {
                  setGraphTab(1);
                }}
                size="xs"
                appearance="ghost"
                className={`${
                  graphTab === 1
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2 hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                OnHand $
              </Button>
              <Button
                onClick={() => setGraphTab(2)}
                appearance="ghost"
                size="xs"
                className={`${
                  graphTab === 2
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                } border-black border-l-2  hover:bg-green-900 hover:text-white focus:bg-green-500 focus:text-white`}
              >
                SKUl Count
              </Button>

            </ButtonGroup>
          </ButtonToolbar>
      </div>
    <ResponsiveContainer
      width={"100%"}
      height={windowSize?.width / 6.5}
      className={`border-[1px]  border-mainGray`}
    >
      <BarChart
        width={500}
        height={300}
        data={barGraphData1?(graphTab===1? barGraphData1?.cluster:barGraphData1?.skul):(graphTab===1? barGraphData?.cluster:barGraphData?.skul)}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 30,
        }}
      >
        <XAxis fontSize={10} stroke="#B5B5B5" dataKey="cluster" angle={-45}
        dx={0}
        dy={15}
        minTickGap={-200}
        axisLine={false}/>
        <YAxis stroke="#B5B5B5" fontSize={10}  tickFormatter={(value: any) => value && `${graphTab===1?'$':''}${formatAmount(value)}`}/>
        <Tooltip cursor={{fill: 'transparent'}} content={($) => <CustomTooltip data={$} graphTab={graphTab}/>} />
        <Legend verticalAlign="top" />
        <Bar dataKey="notZD" onClick={($) => onbarClick({ plant: $?.cluster })} name='Other MRP'  barSize={60} stackId="a" fill="#4ACB4A" >
        
        </Bar>
          <Bar dataKey="ZD" onClick={($) => onbarClick({ plant: $?.cluster })}  name='ZD'  barSize={60}  stackId="a" fill="#57A4AD" >
         
          </Bar>
      </BarChart>
    </ResponsiveContainer>
    </div>
  );
};


const CustomTooltip = (props: any) => {
  const { active, payload } = props.data;

  if (active && payload && payload.length) {
    return (
      <>
        <div className="bg-[#353535] p-3">
          <>
            <p className="text-white text-0.95vw">
              {payload[0]?.payload?.cluster}
            </p>
            {payload?.map(($: any) => {
              return (
                <>
                  <p className="text-white text-0.7vw">
                    {$?.name!=='notZD'?$?.name?.toUpperCase():'Non ZD'} : {props?.graphTab===1?'$':''}{formatNumber(parseFloat($?.value))}
                  </p>
                </>
              );
            })}
          </>
        </div>
      </>
    );
  }

  return null;
};

export default InactiveInventoryZDBarGraph;
