export const LOCAL_API_URL = `http://localhost:7071/api`;
export let deploymentURL = "";
export let AD_tenantID = "";
export let AD_appId = "";
export let AD_appSecret = "";
export let SUBSCRIPTION_KEY = "";
export let API_URL = "";
export let BACKEND_API_URL = "";
export let INSTRUMENTATION_KEY=""

const env = process.env.NODE_ENV;

if (env === "production") {
    deploymentURL = "https://graybar.planningplatformanalytics.com/";
    AD_tenantID = "8fba9776-ec00-4802-bf6c-8477d5e7d4be";
    AD_appId = "62bfa7dc-9ddb-442e-aaa0-62e464a6f880";
    AD_appSecret = "Qoh8Q~a.n3ofDnJ4zukIjDhGH~czDzR-l6U3tdjD";
    SUBSCRIPTION_KEY = "256d8f9bb2a44f7a99f2d9659fa1ef1a";
    INSTRUMENTATION_KEY="bdb257c8-391b-4ce9-ac89-7dfb3b46b6a1";
    BACKEND_API_URL = `https://graybar-planning-api-services.azure-api.net/graybar-functionapp-scenarioPlanning`;
    // BACKEND_API_URL = `http://localhost:7071/api`
} else if (env === "development") {
    // deploymentURL = "https://graybar-functionapp-scenarioplanning-dev.azurewebsites.net/";
    deploymentURL = "http://localhost:3003/";
    AD_tenantID = "8fba9776-ec00-4802-bf6c-8477d5e7d4be";
    AD_appId = "38a09055-195d-40cf-9584-b68de455fea4";
    AD_appSecret = "DU-8Q~SNGv6TEeKnl3ZkxHjnecvhpfc85bueObYS";
    // SUBSCRIPTION_KEY = "e0d7ae1097a9493087b5f3cda7f8567a";
    SUBSCRIPTION_KEY = "256d8f9bb2a44f7a99f2d9659fa1ef1a";
    INSTRUMENTATION_KEY="bdb257c8-391b-4ce9-ac89-7dfb3b46b6a1";
    BACKEND_API_URL = `https://graybar-planning-api-services.azure-api.net/graybar-functionapp-scenarioPlanning`;
    // BACKEND_API_URL = `http://localhost:7071/api`
} else {
    deploymentURL = "https://graybar-scenarioplanning.azurewebsites.net/";
    AD_tenantID = "8fba9776-ec00-4802-bf6c-8477d5e7d4be";
    AD_appId = "62bfa7dc-9ddb-442e-aaa0-62e464a6f880";
    AD_appSecret = "Qoh8Q~a.n3ofDnJ4zukIjDhGH~czDzR-l6U3tdjD";
    SUBSCRIPTION_KEY = "256d8f9bb2a44f7a99f2d9659fa1ef1a";
    INSTRUMENTATION_KEY="bdb257c8-391b-4ce9-ac89-7dfb3b46b6a1";
    BACKEND_API_URL = `https://graybar-planning-api-services.azure-api.net/graybar-functionapp-scenarioPlanning`;
    // BACKEND_API_URL = `http://localhost:7071/api`;
}