import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import { getGBPAlerts, getInventoryAlerts } from "../../queries/apiQueries";
import { AlertData, GBPFilters } from "../../model/forecastModel";
import { Loader } from "rsuite";
import { useSelector } from "react-redux";
import { appInsights } from "../router";
import { formatAmount, formatNumber } from "../../utils/commonFunctions";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const InventoryAlerts = () => {
  const selectedGBPFilter = useSelector(
    (state: any) => state.CommonReducer.selectedGBPFilter
  );
  const token = useSelector((state: any) => state.CommonReducer.token);

  const [alertData, setAlertData] = useState<any>({
    Overstock1: { count: 0 },
    Inactive1: { count: 0 },
    Inactive2: { count: 0 },
    Inactive3: { count: 0 },
  });
  const [loading, setLoading] = useState(false);
  const [selectedFilterData, setSelectedFilterData] = useState<GBPFilters>({
    plant: [],
    l2: [],
    l3: [],
    materialDescription: [],
    material: [],
    mrpType: [],
    futureSegmentation: [],
    vendorName: [],
    clustername: [],
    alerts: [],
    reviewedFlag: [],
    poAlert: [],
    adjustedLogic: [],
    poType: [],
    pfxpg: [],
  });

  const inactiveTabs = {
    "High Days of Inventory": true,
    "Unconstrained Overstock": true,
    "Low Days of Inventory": true,
  };
  const getInventoryAlertData = async () => {
    getInventoryAlerts(selectedGBPFilter)
      .then((response) => {
        if (response?.status === 200) {
          let obj: any = new Object();

          response?.data?.data.forEach((value: any) => {
            obj[value?.title] = {
              count: value?.totalCount,
              amount: value?.amount,
            };
          });
          console.log(obj);
          setAlertData(obj);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: `Navigate to Inventory Alerts by ${localStorage.getItem("name")}`,
    });
    if (selectedGBPFilter) {
      setSelectedFilterData(selectedGBPFilter);
    }
  }, [selectedGBPFilter]);

  useEffect(() => {
    setLoading(true);
    if (token && Object.keys(selectedGBPFilter).length !== 0) {
      getInventoryAlertData();
    }
  }, [selectedGBPFilter, token]);

  return (
    <>
      <div className="p-2 w-full flex justify-center mt-[1.2dvw]">
        {loading && (
          <Loader size="md" backdrop inverse center content="loading..." />
        )}

        {!loading && (
          <div className="w-[95%] ">
            <div className="w-full border-b-2 border-teal-700"></div>

            <div className="w-full grid grid-cols-3">
              <div>
                <div className="w-full flex justify-center">
                  <span className="text-1.1vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                    Proactive Overstock Managment
                  </span>
                </div>

                <div className="w-full  justify-around relative top-[-1.5vw] h-full">
                  <div className="flex flex-col h-full">
                    <div className="flex justify-evenly flex-row-reverse mt-[2.5vw]">
                      <div className="flex justify-center mt-[1vw] ">
                        <Link
                          to={{
                            pathname: "overstock-inventory-managment",
                            search: `?type=2`,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card
                            style={{ backgroundColor: "#000000" }}
                            className="h-[10vw] w-[12.5vw]"
                          >
                            <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                              <div className="w-full h-full grid grid-cols-1 gap-1">
                                <span
                                  className={` text-0.95vw text-center text-mainGreen font-medium`}
                                >
                                  {/* High Overstock <br /> <span className="text-0.7vw">(End of Lead Time)</span> */}
                                  Constrained Overstock
                                </span>
                                <span className="text-white text-1.5vw text-center  font-semibold">
                                  {formatNumber(alertData["Overstock4"]?.count)}
                                </span>
                                <span className="text-white text-0.95vw text-center  font-semibold">
                                  Overstock: $
                                  {formatAmount(
                                    alertData["Overstock4"]?.amount
                                  )}
                                </span>
                                <div className="w-full flex justify-end ">
                                  <KeyboardDoubleArrowRightIcon
                                    className="text-mainGreen "
                                    sx={{
                                      fontSize: {
                                        xs: 10,
                                        sm: 20,
                                        md: 30,
                                        lg: 30,
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Link>
                      </div>

                      <div className="flex justify-center mt-[1vw]  ">
                        <Link
                          to={{
                            pathname: "po-inventory-managment",
                            search: `?type=0`,
                          }}
                          style={{ textDecoration: "none" }}
                        >
                          <Card
                            style={{ backgroundColor: "#000000" }}
                            className="h-[10vw] w-[12.5vw]"
                          >
                            <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full !pt-[4px]">
                              <div className="w-full h-full grid grid-cols-1">
                                <span
                                  className={` text-0.95vw text-center text-[#00FFFF] font-medium `}
                                >
                                  Potential Overstock <br />{" "}
                                  <span className="text-0.7vw">
                                    (Due to Open POs)
                                  </span>
                                  {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                                </span>
                                <span className="text-white text-1.5vw text-center  font-semibold">
                                  {formatNumber(alertData["Overstock2"]?.count)}
                                </span>
                                <span className="text-white text-0.95vw text-center  font-semibold">
                                  On Order: $
                                  {formatAmount(
                                    alertData["Overstock2"]?.amount
                                  )}
                                </span>
                                <div className="w-full flex justify-end ">
                                  <KeyboardDoubleArrowRightIcon
                                    className="text-[#00FFFF] "
                                    sx={{
                                      fontSize: {
                                        xs: 10,
                                        sm: 20,
                                        md: 30,
                                        lg: 30,
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Link>
                      </div>
                    </div>

                    <div className="flex justify-evenly mt-[1.5vw]">
                      <div className="flex justify-center mt-[1vw]  ">
                        <Link
                          to={{
                            pathname: "overstock-inventory-managment",
                            search: `?type=1`,
                          }}
                         style={{ textDecoration: "none"}}
                         
                        >
                          <Card
                            style={{
                              backgroundColor: "#000000",
                              opacity: inactiveTabs["High Days of Inventory"]
                                ? 0.5
                                : 1,
                            }}
                            className="h-[10vw] w-[12.5vw]"
                          >
                            <CardContent
                              className={`bg-black border-${
                                inactiveTabs["High Days of Inventory"]
                                  ? "gray"
                                  : "mainGreen"
                              } border-[1px] rounded-2xl h-full !pt-[4px]`}
                            >
                              <div className="w-full h-full grid grid-cols-1">
                                <span
                                  className={` text-0.95vw text-center text-${
                                    inactiveTabs["High Days of Inventory"]
                                      ? "gray-400"
                                      : "mainGreen"
                                  } font-medium`}
                                >
                                  High Days of Inventory <br />
                                  <span className="text-0.7vw">
                                    (Last 4 Months)
                                  </span>
                                  {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                                </span>
                                <span className="text-white text-1.5vw text-center  font-semibold">
                                  {formatNumber(alertData["Overstock3"]?.count)}
                                </span>
                                <span className="text-white text-0.95vw text-center  font-semibold">
                                  Onhand: $
                                  {formatAmount(
                                    alertData["Overstock3"]?.amount
                                  )}
                                </span>
                                <div className="w-full flex justify-end ">
                                  <KeyboardDoubleArrowRightIcon
                                     className={`${
                                      inactiveTabs["High Days of Inventory"] ?`text-gray-400` : `text-mainGreen` }`}
                                    sx={{
                                      fontSize: {
                                        xs: 10,
                                        sm: 20,
                                        md: 30,
                                        lg: 30,
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Link>
                      </div>
                      <div className="flex justify-center mt-[1vw]  ">
                        <Link
                          to={{
                            pathname: "overstock-inventory-managment",
                            search: `?type=1`,
                          }}
                        style={{ textDecoration: "none" }}
                         
                        >
                          <Card
                            style={{
                              backgroundColor: "#000000",
                              opacity: inactiveTabs["Unconstrained Overstock"]
                                ? 0.5
                                : 1,
                            }}
                            className="h-[10vw] w-[12.5vw]"
                          >
                            <CardContent
                              className={`bg-black border-${
                                inactiveTabs["Unconstrained Overstock"]
                                  ? "gray"
                                  : "mainGreen"
                              } border-[1px] rounded-2xl h-full !pt-[4px]`}
                            >
                              <div className="w-full h-full grid grid-cols-1">
                                <span
                                  className={` text-0.95vw text-center text-${
                                    inactiveTabs["Unconstrained Overstock"]
                                      ? "gray-400"
                                      : "mainGreen"
                                  } font-medium`}
                                >
                                  Unconstrained Overstock <br />
                                  <span className="text-0.7vw">
                                    (with Exclusions)
                                  </span>
                                  {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                                </span>
                                <span className="text-white text-1.5vw text-center  font-semibold">
                                  {/* {formatNumber(alertData["Overstock3"]?.count)} */}{" "}
                                  TBD
                                </span>
                                <span className="text-white text-0.95vw text-center  font-semibold">
                                  Onhand:
                                  {/* {formatAmount(alertData["Overstock3"]?.amount)} */}
                                  TBD
                                </span>
                                <div className="w-full flex justify-end ">
                                  <KeyboardDoubleArrowRightIcon
                                   className={`${
                                    inactiveTabs["Unconstrained Overstock"] ?`text-gray-400` : `text-mainGreen` }`}
                                    sx={{
                                      fontSize: {
                                        xs: 10,
                                        sm: 20,
                                        md: 30,
                                        lg: 30,
                                      },
                                    }}
                                  />
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* <div className="flex justify-center mt-[1vw] ">
                    <Card
                      style={{ backgroundColor: "#000000" }}
                      className="h-[10vw] w-[12.5vw]"
                    >
                      <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full !pt-[4px]">
                        <div className="w-full h-full grid grid-cols-1 gap-1">
                          <span
                            className={` text-0.95vw text-center text-[#00FFFF] font-medium`}
                          >
                           
                           Overstock on Exclusion Items
                          </span>
                          <span className="text-white text-1.5vw text-center  font-semibold">
                          {formatNumber(alertData['Overstock1']?.count)}
                          </span>
                          <span className="text-white text-0.95vw text-center  font-semibold">
                           Overstock: ${formatAmount(alertData['Overstock1']?.amount)}
                          </span>
                          <div className="w-full flex justify-end ">
                            <Link
                              to={{
                                pathname: "overstock-inventory-managment",
                                search: `?type=0`,
                              }}
                            >
                              <KeyboardDoubleArrowRightIcon
                                className="text-[#00FFFF] "
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div> */}
                  {/* <div className="flex justify-end mt-[1vw] ">
                    <Card
                      style={{ backgroundColor: "#000000" }}
                      className="h-[10vw] w-[12.5vw]"
                    >
                      <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full !pt-[4px]">
                        <div className="w-full h-full grid grid-cols-1 gap-1">
                          <span
                            className={` text-0.95vw text-center text-[#00FFFF] font-medium`}
                          >
                           Unconstrained Overstok withnin LT
                           
                          </span>
                          <span className="text-white text-1.5vw text-center  font-semibold">
                          {formatNumber(alertData['Overstock1']?.count)}
                          </span>
                          <span className="text-white text-0.95vw text-center  font-semibold">
                           Overstock: ${formatAmount(alertData['Overstock1']?.amount)}
                          </span>
                          <div className="w-full flex justify-end ">
                            <Link
                              to={{
                                pathname: "overstock-inventory-managment",
                                search: `?type=0`,
                              }}
                            >
                              <KeyboardDoubleArrowRightIcon
                                className="text-[#00FFFF] "
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </Link>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </div> */}
                </div>
              </div>
              <div className="border-l-2 border-teal-700">
                <div className="w-full flex justify-center">
                  <span className="text-1.1vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                    Insufficient Stockout Management
                  </span>
                </div>

                <div className="w-full relative top-[-1.5vw]">
                  {/* <div className="flex justify-evenly mt-[2.5vw]"> */}
                  <div className="flex justify-center mt-[1vw] ">
                    <Link
                      to={{
                        pathname: "stockout-inventory-managment",
                        search: `?type=1`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-[#00FFFF] border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-[#00FFFF] font-medium`}
                            >
                              Potential Lost Service <br />{" "}
                              <span className="text-0.7vw">
                                (End of 2nd Lead Time)
                              </span>
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                              {formatNumber(alertData["Stockout2"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                              Onhand: $
                              {formatAmount(alertData["Stockout2"]?.amount)}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-[#00FFFF] "
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                  <div className="flex justify-center mt-[1vw]">
                    <Link
                      to={{
                        pathname: "stockout-inventory-managment",
                        search: `?type=0`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-mainGreen font-medium`}
                            >
                              High Stockout <br />
                              <span className="text-0.7vw">
                                {" "}
                                (Last 3 months)
                              </span>
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                              {formatNumber(alertData["Stockout1"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                              Lost Sale: $
                              {formatAmount(alertData["Stockout1"]?.amount)}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen"
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                  {/* </div> */}

                  {/* <div className="flex justify-evenly mt-[1.5vw]"> */}
                  <div className="flex justify-center mt-[1vw]">
                    <Link
                      to={{
                        pathname: "stockout-inventory-managment",
                        search: `?type=2`,
                      }}
                    style={{ textDecoration: "none" }}
                     
                    >
                      <Card
                        style={{
                          backgroundColor: "#000000",
                          opacity: inactiveTabs["High Days of Inventory"]
                            ? 0.5
                            : 1,
                        }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent
                          className={`bg-black border-${
                            inactiveTabs["High Days of Inventory"]
                              ? "gray"
                              : "mainGreen"
                          } border-[1px] rounded-2xl h-full !pt-[4px]`}
                        >
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-${
                                inactiveTabs["High Days of Inventory"] ? "gray-400" : "mainGreen"
                              } font-medium`}
                            >
                              Low Days of Inventory <br />{" "}
                              <span className="text-0.7vw">
                                (Last 4 Months)
                              </span>
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                              {formatNumber(alertData["Stockout3"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                              Onhand: $
                              {formatAmount(alertData["Stockout3"]?.amount)}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className={`${
                                  inactiveTabs["Low Days of Inventory"] ?`text-gray-400` : `text-mainGreen` }`}
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                  {/* <div className="flex justify-center mt-[1vw] w-[12.5vw]">
                    
                    </div> */}
                  {/* </div> */}
                </div>
              </div>

              <div className="border-l-2 border-teal-700">
                <div className="w-full flex justify-center">
                  <span className="text-1.1vw text-white border-[1px] border-teal-700 px-2 py-1 top-[-20px] relative bg-black">
                    Inactive Inventory Managment
                  </span>
                </div>
                <div className="w-full relative top-[-1.5vw]">
                  <div className="absolute  left-[26vw] mt-[11vw] ">
                    <p className="text-white text-0.95vw text-center">
                      Review <br /> Frequency
                    </p>
                    <p className=" text-0.95vw text-white text-center py-1 bg-black px-2  border-2 border-[#00FFFF] ">
                      Daily
                    </p>
                    {/* <p className=" text-1.5vw text-white mx-2">/</p> */}
                    <p className=" text-0.95vw text-white text-center bg-black px-2 py-1 border-2 border-mainGreen ">
                      Monthly
                    </p>
                  </div>
                  <div className="flex justify-center mt-[1vw] ">
                    <Link
                      to={{
                        pathname: "inactive-inventory-managment",
                        search: `?type=0`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-mainGreen font-medium`}
                            >
                              Potential Inactive <br />
                              <span className="text-0.7vw">
                                (No demand in last 6M)
                              </span>
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                              {formatNumber(alertData["Inactive1"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                              Onhand: $
                              {formatAmount(alertData["Inactive1"]?.amount) ||
                                0}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen "
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                  <div className="flex justify-center mt-[1vw]">
                    <Link
                      to={{
                        pathname: "inactive-inventory-managment",
                        search: `?type=1`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-mainGreen font-medium`}
                            >
                              Untagged Inactive
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                              {formatNumber(alertData["Inactive2"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                              Onhand: $
                              {formatAmount(alertData["Inactive2"]?.amount) ||
                                0}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen "
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                  <div className="flex justify-center mt-[1vw]">
                    <Link
                      to={{
                        pathname: "inactive-inventory-managment",
                        search: `?type=2`,
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <Card
                        style={{ backgroundColor: "#000000" }}
                        className="h-[10vw] w-[12.5vw]"
                      >
                        <CardContent className="bg-black border-mainGreen border-[1px] rounded-2xl h-full !pt-[4px]">
                          <div className="w-full h-full grid grid-cols-1">
                            <span
                              className={` text-0.95vw text-center text-mainGreen  font-medium`}
                            >
                              Ageing Inactive <br />
                              <span className="text-0.7vw">
                                (Age {">"} 8 Months){" "}
                              </span>
                              {/* {element?.alerts.replace(
                                      /([a-z])([A-Z])/g,
                                      "$1 $2"
                                    )} */}
                            </span>
                            <span className="text-white text-1.5vw text-center  font-semibold">
                              {formatNumber(alertData["Inactive3"]?.count)}
                            </span>
                            <span className="text-white text-0.95vw text-center  font-semibold">
                              Onhand: $
                              {formatAmount(alertData["Inactive3"]?.amount) ||
                                0}
                            </span>
                            <div className="w-full flex justify-end ">
                              <KeyboardDoubleArrowRightIcon
                                className="text-mainGreen "
                                sx={{
                                  fontSize: {
                                    xs: 10,
                                    sm: 20,
                                    md: 30,
                                    lg: 30,
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default InventoryAlerts;
