export const ALERT_TABS = [
    {
        label: "High Future Forecast",
        value: "HighFutureForecast",
    }, {
        label: "Low Future Forecast",
        value: "LowFutureForecast",
    }, {
        label: "Low Forecast Accuracy",
        value: "LowForecastAccuracy",
    }, {
        label: "Consistent Historical Over Forecasting",
        value: "ConsistentHistoricalOverForecasting",
    }, {
        label: "Consistent Historical Under Forecasting",
        value: "ConsistentHistoricalUnderForecasting",
    
    }, {
        label: "High Outlier Correction",
        value: "HighOutlierCorrection",
    }, {
        label: "Volatile Forecast ",
        value: "VolatileForecast",
    }
]

export const STATUS_DATA=[{ label: 'Reviewed', value: 1 },{ label: 'Not Reviewed', value: 0 } , { label: 'In Progress', value: 2 }]
export const PO_ALERT_DATA=[{ label: 'Automatic', value: 'automatic' },{ label: 'Manual', value: 'manual' }]
export const HIGHSTOCKOUT_ALERT_DATA=[{ label: 'AdjORQ>75PerOnHand', value: 'AdjORQ>75PerOnHand' },{ label: 'Adj ORQ Based on Min ORQ', value: 'Adj ORQ Based on Min ORQ' }]

export const DATA_TABLE=[

    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Material Description',
        key:'material_description'
    },
    {
        label:'PFXPG',
        key:'PFXPG'
    },
    {
        label:'Vendor Number',
        key:'vendor_number'
    },
    {
        label:'Alert Type',
        key:'Alerts'
    },
    {
        label:'Standard Price',
        key:'standard_price'
    },
    {
        label:'$ Delta',
        key:'delta'
    },
    {
        label:'Annual Revenue ($)',
        key:'annualRevenue'
    },
    {
        label:'AvgDemand Last 12M',
        key:'AvgDemand_Last12Months'
    },
    {
        label:'AvgDemand Last 8M',
        key:'AvgDemand_Last8Months'
    },
    {
        label:'AvgDemand Last 4M',
        key:'AvgDemand_Last4Months'
    },
    {
        label:'AvgForecast Last 4M',
        key:'AvgForecast_Last4Months'
    },
    {
        label:'Last 4M Accuracy',
        key:'Last4Month_Accuracy'
    },
    {
        label:'Last 4M BIAS',
        key:'Last4Month_BIAS'
    },
    {
        label:'Lag 2 M1',
        key:'Lag2_Foreast'
    },
    {
        label:'Lag 1 M1',
        key:'Lag1_Foreast'
    },
    {
        label:'CM Open SO',
        key:'CurrentMonthOpenSalesQty'
    },
    {
        label:'CM Forecast',
        key:'forecastMonth0'
    },
    {
        label:'Adjusted CM Forecast',
        key:'AdjustedForecast0'
    },
    {
        label:'NM Forecast',
        key:'forecastMonth1'
    },
    {
        label:'Adjusted NM Forecast',
        key:'AdjustedForecast1'
    },
    {
        label:'Comments',
        key:'Comments'
    },
    {
        label:'Reviewed?',
        key:'isReviewed'
    },
    
    
]

export const CUSTOMER_LEVEL_TABLE=[

    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Sold To',
        key:'Customer_Number'
    },
    {
        label:'6 Month  Qty',
        key:'sum_qty'
    },
    {
        label:'M-6 Qty',
        key:'M_6_Qty'
    },
    {
        label:'M-5 Qty',
        key:'M_5_Qty'
    },
    {
        label:'M-4 Qty',
        key:'M_4_Qty'
    },
    {
        label:'M-3 Qty',
        key:'M_3_Qty'
    },
    {
        label:'M-2 Qty',
        key:'M_2_Qty'
    },
    {
        label:'M-1 Qty',
        key:'M_1_Qty'
    },
    
    
    
]

export const MONTH_Filter=[{ label: '0', value: 0 },{ label: '1', value: 1 },{ label: '2', value: 2 }]

export const GRAPH_TABLE=[
    {
        label:'Date',
        key:'forecastDate'
    },
    {
        label:'Forecast',
        key:'toolforecast'
    },
    {
        label:'CY Demand',
        key:'processedQty'
    },
    {
        label:'Y-1 Demand',
        key:'processedQtyLY'
    },
    {
        label:'Y-2 Demand',
        key:'processedQtyL2Y'
    }
]

export const LONGTERM_TABLE=[
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Actual Qty 6M',
        key:'ActualQty_Last6M'
    },
    {
        label:'Post Transactional Correction (GB)',
        key:'NormalizedQty_Transactional_Last6M'
    },
    {
        label:'Post Monthly Correction (PA)',
        key:'NormalizedPAQty_Monthly_Last6M'
    },
    {
        label:'Reduction % By GB',
        key:'ReductionByGraybar'
    },
    {
        label:'Reduction % By PA',
        key:'ReductionByPA'
    },
    {
        label:'Overall Reduction %',
        key:'OverAllReduction'
    },
]

export const LONGTERM_CUSTOMER_TABLE=[
    {
        label:'Material',
        key:'MATERIAL_MATERIAL'
    },
    {
        label:'Plant',
        key:'PLANT_PLANT'
    },
    {
        label:'Customer',
        key:'Customer_Number'
    },
    {
        label:'6 Month Actual Qty',
        key:'sum_qty'
    },
    {
        label:'M-6',
        subHeader:[{
            label:'Actual Qty',
            key:'M_6_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_6_Qty'
        },{
            label:'#Transactions',
            key:'M_6_Transactions'
        },]
    },
    {
        label:'M-5',
        subHeader:[{
            label:'Actual Qty',
            key:'M_5_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_5_Qty'
        },{
            label:'#Transactions',
            key:'M_5_Transactions'
        },]
    }, {
        label:'M-4',
        subHeader:[{
            label:'Actual Qty',
            key:'M_4_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_4_Qty'
        },{
            label:'#Transactions',
            key:'M_4_Transactions'
        },]
    }, {
        label:'M-3',
        subHeader:[{
            label:'Actual Qty',
            key:'M_3_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_3_Qty'
        },{
            label:'#Transactions',
            key:'M_3_Transactions'
        },]
    }, {
        label:'M-2',
        subHeader:[{
            label:'Actual Qty',
            key:'M_2_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_2_Qty'
        },{
            label:'#Transactions',
            key:'M_2_Transactions'
        },]
    }, {
        label:'M-1',
        subHeader:[{
            label:'Actual Qty',
            key:'M_1_Actual_Qty'
        },{
            label:'Post Trans. Corr. (GB)',
            key:'M_1_Qty'
        },{
            label:'#Transactions',
            key:'M_1_Transactions'
        },]
    },
  
]

export const INACTIVE_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Cluster',
        key:'cluster'
    },
    {
        label:'MRP Type',
        key:'MRP_Type'
    },
    {
        label:'Vendor Name',
        key:'Vendor_Code'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'OnHand ($)',
        key:'onHand$'
    },
    {
        label:'OnHand (Qty)',
        key:'onHand'
    },
    {
        label:'Open EROs',
        key:'Open_EROs'
    },
]

export const OVERSTOCK_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Cluster',
        key:'cluster'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'Vendor Name',
        key:'Vendor_Code'
    },
    {
        label:'MRP Type',
        key:'mrp_type'
    },
    {
        label:'MRP Group',
        key:'mrp_group'
    },
    {
        label: "OnHand ($)",
        key: "Onhand$Value",
      },
    {
        label:'OnHand (Qty)',
        key:'onHand'
    },
    {
        label: "Constrained Overstock (Qty)",
        key: "OverStockQty",
      },
    {
        label:'ATP',
        key:'atp'
    },
    {
        label:'Net Inventory Qty',
        key:'Net_Inv_Qty'
    },
    {
        label:'Eff. SS (Qty)',
        key:'Effective_SS'
    },
    {
        label:'Adj. ORQ (Qty)',
        key:'adjusted_orq'
    },

]

export const STOCKOUT_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Cluster',
        key:'cluster'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'Vendor Name',
        key:'Vendor_Code'
    },
    
]

export const PO_INV_TABLE=[
    {
        label:'Material',
        key:'material'
    },
    {
        label:'Plant',
        key:'plant'
    },
    {
        label:'Category',
        key:'category'
    },
    {
        label:'Segment',
        key:'segment'
    },
    {
        label:'Vendor Name',
        key:'Vendor_Code'
    },
    {
        label:'MRP Type',
        key:'mrp_type'
    },
    {
        label:'MRP Group',
        key:'mrp_group'
    },
    {
        label:'OnHand ($)',
        key:'Onhand$Value'
    },
    {
        label:'OnHand (Qty)',
        key:'onhand'
    },
    {
        label:'Potential Overstock (Qty)',
        key:'PotentialOverStock'
    },
    {
        label:'Potential Overstock ($)',
        key:'PotentialOverStock$'
    },
    {
        label:'Open EROs (Qty)',
        key:'open_eros'
    },
    {
        label:'Total Dmd Within LT (Qty)',
        key:'Total_Dmd_Within_LT_Custom'
    },
    {
        label:'Dp Dmd OLT',
        key:'Dep_Dmd_OLT'
    },
    {
        label:'Ind Dmd Wlt',
        key:'IND_Dmd_Within_LT'
    },
    
   
    {

        label:'ATP Custom',
        key:'ATPCustom'
    },
    
    {
        label:'Net Inv Qty (Custom)',
        key:'Net_Inventory_Quantity'
    },
    {
        label:'Eff. SS (Qty)',
        key:'effective_ss'
    },
    {
        label:'Adj. ORQ (Qty)',
        key:'Adjusted_ORQ'
    },

    {
        label:'Max Stock Level',
        key:'Max_Stock_Level'
    },

    // {
    //     label:'Inventory Threshold (Qty)',
    //     key:'InventoryThreshold'
    // },
    {

        label:'Monthly Demand (Avg. Last 6M)',
        key:'AvgMonthlyDemandLast6M'
    },
    {
        label:'Monthly Forecast (next 6M)',
        key:'AvgFutureForeastNext6M'
    },
    
    {
        label:'PO Number',
        key:'ponumber'
    },
    {
        label:'Req Tracking Number',
        key:'REQ_TRACKING_NUMBER_BEDNR'
    },
    {
        label:'PO Status',
        key:'openClosePOFlag'
    },
    {
        label:'PO Qty',
        key:'poqty'
    },
    {
        label:'PO Value ($)',
        key:'povalue'
    },
    {
        label:'PO Type',
        key:'poTypeFlag'
    },
    {
        label:'Count of Open PO',
        key:'POCount'
    },
    {
        label:'Rep LT (Weeks)',
        key:'Rep_LT'
    },
    {
        label:'LT Change 6 Months',
        key:'LTChange6Months'
    },
    {
        label:'Potential Actions',
        key:'RecommendedAction'
    },
    {
        label:'Recommended Date',
        key:'RecommendedDate'
    },
    {
        label:'Review Status',
        key:'isReviewed'
    },
    
]


export const NAVBAR_FORECAST=[
    { label: 'Forecast Summary', value: '/forecast/forecast-summary',active:'forecast-summary' },
    { label: 'Alerts', value: '/forecast/forecast-alerts',active:'forecast-alerts' },
    { label: 'Forecast Overview', value: '/forecast/forecast-overview' ,active:'forecast-overview'},
]

export const NAVBAR_INVENTORY=[
    { label: 'Inventory Alerts', value: '/inventory/inventory-alerts' ,active:'inventory-alerts'}
]


export const DUMMY= [
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },


];

export const DUMMYInactive= [
    {
        name: 'Cluster 1',
        uv: 3000,
        pv: 1398,
        amt: 4210,
      },
      {
        name: 'Cluster 2',
        uv: 2000,
        pv: 1000,
        amt: 4290,
      },
      {
        name: 'Cluster 3',
        uv: 2780,
        pv: 1500,
        amt: 4000,
      },


];

export const DUMMYinactiveWaterfall= [
    { 
       name: "Total Inactive Inv", 
       uv: 2400, 
       pv: 0 
    },
    {
       name: "Unaddressable inactive", 
       uv: -800, 
       pv: 2400 
    },
    {
       name: "Addressable inactive",
       uv: 1600,
       pv: 0     
    },
 
 
 ];


export const DUMMY1= [
    { 
       name: "Inactive Inventory", 
       uv: 2400, 
       pv: 0 
    },
    {
       name: "< 8 Months", 
       uv: -600, 
       pv: 2400 
    },
    {
       name: "8-12 Months",
       uv: 400,
       pv: 1800 
    },
    { name: ">12 Months", uv: 1800, pv: 0 },
  
 
 
 ];

export const SUMMARY_EXPORT=[
    {label:'Plant Code',key:'Plant_Code'},
    {label:'Plant',key:'PLANT_PLANT'},
    {label:'Material',key:'MATERIAL_MATERIAL'},
    {label:'Category (added/excluded from last run)',key:'Category'},
]